<template>
  <Popup
    :title="title"
    :page="page"
    :name="name"
    :parent="parent"
    :width="width"
    :height="height"
    @close="$emit('close')"
    class="arrestation default"
  >
    <div class="_content col-pc-10 col-lgmobile-10 col-margin-auto">
      <div class="arrestation">
        <div class="main-form">
          <div class="frame">
            <div
              class="row archived-warning"
              v-if="action === 'view'"
              style="margin-bottom: 15px;"
            >
              <div class="banner orange error">
                <p class="uk-text-center">{{ $t('arrestation_component.read-only') }}</p>
              </div>
            </div>
            <div class="row">
              <div class="form">
                <div class="tr">
                  <div class="td date col-pc-6 col-lgmobile-6">
                    <p>
                      {{ $t('arrestation_component.date') }}
                      <a
                        v-if="action !== 'view' && moment(currentDateTime).format('YYYY-MM-DD') !== arrestation.date"
                        @click="updateDate"
                      >{{ $t('arrestation_component.now') }}</a>
                    </p>
                    <div class="custom-input primary">
                      <VueDateTimePicker
                        id="arrestation-date"
                        :onlyDate="true"
                        inputClasses="no-border"
                        format="YYYY-MM-DD"
                        formatted="YYYY-MM-DD"
                        v-model="arrestation.date"
                        :disabled="action === 'view'"
                      ></VueDateTimePicker>
                    </div>
                  </div>
                  <div class="td time col-pc-6 col-lgmobile-6">
                    <p>
                      {{ $t('arrestation_component.time') }}
                      <a
                        v-if="action !== 'view' && moment(currentDateTime).format('HH:mm') !== arrestation.time"
                        @click="updateTime"
                      >{{ $t('arrestation_component.now') }}</a>
                    </p>
                    <div class="custom-input primary">
                      <VueDateTimePicker
                        id="arrestation-time"
                        :onlyTime="true"
                        inputClasses="no-border"
                        :format="moment.locale() === 'en' ? 'h:mm A' : 'HH:mm'"
                        formatted="LT"
                        outputFormat="HH:mm:ss"
                        v-model="arrestation.time"
                        :disabled="action === 'view'"
                      ></VueDateTimePicker>
                    </div>
                  </div>
                </div>
                <div class="tr">
                  <div class="td col-pc-12 col-lgmobile-12">
                    <p>{{ $t('arrestation_component.location') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12 blank"
                        type="text"
                        :readonly="action === 'view'"
                        :placeholder="$t('arrestation_component.location_placeholder')"
                        v-model="arrestation.location"
                      />
                    </div>
                  </div>
                </div>
                <div class="tr">
                  <div class="td full-name col-pc-6 col-lgmobile-12">
                    <p>{{ $t('arrestation_component.full-name') }}</p>
                    <CivilianSearchInput
                      class="searchbar custom-input primary col-pc-12 col-lgmobile-12"
                      ref="civilianSearchInput"
                      :id="defendantId"
                      :readonly="disableCivilianSearch || action === 'view'"
                      @selectedCivilian="selectCivilian($event)"
                    />
                  </div>
                  <div class="td gender col-pc-6 col-lgmobile-6">
                    <p>{{ $t('arrestation_component.gender') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12 blank"
                        type="text"
                        v-model="arrestation.defendant.gender"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <!-- <div class="tr">
                  <div class="td street-number col-pc-6 col-lgmobile-6">
                    <p>{{ $t('arrestation_component.address') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12"
                        :class="{ readonly_empty: arrestation.violator.streetNumber === '' }"
                        type="text"
                        v-model="arrestation.violator.streetNumber"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="td city col-pc-6 col-lgmobile-6">
                    <p>{{ $t('arrestation_component.city') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12"
                        :class="{ readonly_empty: arrestation.violator.city === '' }"
                        type="text"
                        v-model="arrestation.violator.city"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="tr">
                  <div class="td state col-pc-6 col-lgmobile-6">
                    <p>{{ $t('arrestation_component.state') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12"
                        :class="{ readonly_empty: arrestation.violator.state === '' }"
                        type="text"
                        v-model="arrestation.violator.state"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="td postal-code col-pc-6 col-lgmobile-6">
                    <p>{{ $t('arrestation_component.postal-code') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12"
                        :class="{ readonly_empty: arrestation.violator.postalCode === '' }"
                        type="text"
                        v-model="arrestation.violator.postalCode"
                        readonly
                      />
                    </div>
                  </div>
                </div>-->
              </div>
              <div class="form offences">
                <h3>{{ $t('arrestation_component.offences') }}</h3>
                <div class="tr offence-titles">
                  <div class="th col-pc-6 col-lgmobile-6">
                    <p>{{ $t('arrestation_component.description') }}</p>
                  </div>
                  <div class="th col-pc-3 col-lgmobile-3">
                    <p>{{ $t('arrestation_component.act') }}</p>
                  </div>
                  <div
                    class="th col-pc-3 col-lgmobile-3"
                    v-if="action !== 'view'"
                  >
                    <p>{{ $t('arrestation_component.charge') }}</p>
                  </div>
                  <div
                    class="th col-pc-3 col-lgmobile-3"
                    v-if="action !== 'view'"
                  >
                    <p>{{ $t('actions.delete') }}</p>
                  </div>
                  <div
                    class="th col-pc-6 col-lgmobile-6"
                    v-if="action === 'view'"
                  >
                    <p>{{ $t('arrestation_component.charge') }}</p>
                  </div>
                </div>
                <div
                  class="offence"
                  v-for="(offence, index) in arrestation.offences"
                  :key="'offence-' + index"
                >
                  <div class="tr">
                    <div class="td description col-pc-6 col-lgmobile-6">
                      <div class="custom-input">
                        <input
                          class="col-all-12 blank"
                          type="text"
                          :readonly="action === 'view'"
                          :placeholder="$t('arrestation_component.description_placeholder')"
                          v-model="offence.description"
                        />
                      </div>
                    </div>
                    <div class="td act col-pc-3 col-lgmobile-3">
                      <div class="custom-input">
                        <input
                          class="col-all-12 blank"
                          type="text"
                          :readonly="action === 'view'"
                          :placeholder="'(' + $t('arrestation_component.act_placeholder') + ')'"
                          v-model="offence.act"
                        />
                      </div>
                    </div>
                    <div
                      class="td charge col-pc-3 col-lgmobile-3"
                      v-if="action !== 'view'"
                    >
                      <button
                        class="primary"
                        @click="offence.charge.isOpen = !offence.charge.isOpen"
                      >
                        {{ $t('arrestation_component.charge') }}
                      </button>
                    </div>
                    <div
                      class="td charge col-pc-3 col-lgmobile-3"
                      v-if="action !== 'view'"
                    >
                      <button
                        class="primary"
                        @click="deleteOffence(index)"
                      >{{ $t('actions.delete') }}</button>
                    </div>
                    <div
                      class="td charge col-pc-6 col-lgmobile-6"
                      v-if="action === 'view'"
                    >
                      {{ $t('charge-status.' + offence.charge.status)
                      }}{{
                        offence.charge.status !== 'NOT_GUILTY' && offence.charge.status !== 'PENDING'
                          ? `, ${offence.charge.time} ${$t('timeframe.' + offence.charge.timeFrame)} ${$t(
                              'timeframe.of'
                            )} ${$t(`arrestation_component.${offence.charge.type}`)}`
                          : ''
                      }}
                    </div>
                  </div>
                  <div
                    class="charge-submenu col-pc-12 col-mobile-12"
                    v-if="offence.charge.isOpen && action !== 'view'"
                  >
                    <div class="title">
                      <h3>{{ $t('arrestation_component.charge') }}</h3>
                    </div>
                    <div class="content">
                      <div class="row col-pc-12 col-lgmobile-12">
                        <div class="status col-pc-6 col-mobile-6">
                          <vSelect
                            :clearable="false"
                            :disabled="action === 'view'"
                            v-model="offence.charge.status"
                            :options="[
                              { label: $t('arrestation_component.guilty'), value: 'GUILTY' },
                              { label: $t('arrestation_component.not-guilty'), value: 'NOT_GUILTY' },
                              { label: $t('arrestation_component.pending'), value: 'PENDING' }
                            ]"
                            :reduce="status => status.value"
                            class="custom-select line"
                          ></vSelect>
                        </div>
                        <div
                          class="type-of-punishment col-pc-6 col-mobile-6"
                          v-if="offence.charge.status === 'GUILTY'"
                        >
                          <vSelect
                            :clearable="false"
                            :disabled="action === 'view'"
                            v-model="offence.charge.type"
                            :options="[
                              { label: $t('arrestation_component.community-work'), value: 'COMMUNITY_WORK' },
                              { label: $t('arrestation_component.jail-time'), value: 'JAIL_TIME' }
                            ]"
                            :reduce="type => type.value"
                            class="custom-select line"
                          ></vSelect>
                        </div>
                      </div>
                      <div
                        class="row second col-pc-12 col-lgmobile-12"
                        v-if="offence.charge.status === 'GUILTY'"
                      >
                        <div class="punishment-time col-pc-6">
                          <div class="amount col-pc-6">
                            <CustomInput
                              class="col-pc-12 custom-input custom-height primary line"
                              inputClasses="col-pc-12"
                              :readonly="action === 'view'"
                              min="0"
                              step="0.01"
                              oninput="validity.valid || (value = '');"
                              v-model.number="offence.charge.time"
                              type="number"
                            ></CustomInput>
                          </div>
                          <div class="timeframe col-pc-6">
                            <vSelect
                              :disabled="action === 'view'"
                              :clearable="false"
                              v-model="offence.charge.timeFrame"
                              :options="[
                                { label: $t('arrestation_component.days'), value: 'DAYS' },
                                { label: $t('arrestation_component.months'), value: 'MONTHS' },
                                { label: $t('arrestation_component.years'), value: 'YEARS' }
                              ]"
                              :reduce="timeFrame => timeFrame.value"
                              class="custom-select line col-pc-12"
                            ></vSelect>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                v-if="action !== 'view'"
                style="margin-top: 10px;"
              >
                <a @click="addOffence">{{ $t('arrestation_component.add-offences') }}</a>
              </div>
              <div
                class="button"
                v-if="action !== 'view'"
              >
                <button
                  class="primary col-pc-12 col-lgmobile-12"
                  :disabled="!canSubmit"
                  @click="commitArrestation"
                >
                  {{ $t('actions.submit') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>
<style lang="less" scoped src="@/assets/less/components/reports.less"></style>

<script>
import Popup from '@/components/Popup'
import CivilianSearchInput from '@/components/CivilianSearchInput'
import CustomInput from '@/components/CustomInput'
import vSelect from 'vue-select'

export default {
  components: {
    Popup,
    CivilianSearchInput,
    CustomInput,
    vSelect
  },
  data() {
    return {
      arrestation: {
        date: this.moment().format('YYYY-MM-DD'),
        time: this.moment().format('HH:mm'),
        location: '',
        defendant: {
          gender: ''
        },
        offences: [
          {
            description: '',
            act: '',
            charge: {
              status: 'NOT_GUILTY',
              type: 'JAIL_TIME',
              time: 0,
              timeFrame: 'DAYS',
              isOpen: false
            }
          }
        ]
      },
      initialArrestation: null,
      defendantId: null,
      timeUpdateIntervalFn: null,
      timeUpdateTimeoutFn: null,
      currentDateTime: this.moment()
    }
  },
  props: {
    title: String,
    page: String,
    name: String,
    width: Number,
    height: Number,
    parent: Boolean,
    issuer: String,
    action: String,
    defendant: String,
    disableCivilianSearch: {
      type: Boolean,
      default: false
    },
    arrestationId: String
  },
  methods: {
    updateDate() {
      this.arrestation.date = this.moment().format('YYYY-MM-DD')
    },
    updateTime() {
      this.arrestation.time = this.moment().format('HH:mm')
    },
    addOffence() {
      this.arrestation.offences.push({
        description: '',
        act: '',
        charge: {
          status: 'NOT_GUILTY',
          type: 'JAIL_TIME',
          time: 0,
          timeFrame: 'DAYS',
          isOpen: false
        }
      })
    },
    deleteOffence(index) {
      this.arrestation.offences.splice(index, 1)

      if (this.arrestation.offences.length === 0) {
        this.addOffence()
      }
    },
    selectCivilian(civilian) {
      this.arrestation.defendant = civilian
    },
    commitArrestation() {
      if (this.action === 'create') {
        if (!this.canSubmit) {
          return
        }

        const civilian = {
          civilianId: this.arrestation.defendant.id,
          arrestations: {
            create: {
              arrestationDate: new Date(this.arrestation.date + ' ' + this.arrestation.time),
              location: this.arrestation.location,
              issuer: { connect: { id: this.issuer } },
              offences: {
                create: this.arrestation.offences
                  .filter(offence => offence.description && offence.act)
                  .map(offence => {
                    return {
                      description: offence.description,
                      act: offence.act,
                      chargeStatus: offence.charge.status,
                      chargeType: offence.charge.type,
                      chargeTime: offence.charge.time,
                      chargeTimeFrame: offence.charge.timeFrame
                    }
                  })
              }
            }
          }
        }

        this.$store.dispatch('civilian/updateCivilian', civilian).then(response => {
          this.$notify({
            type: 'success',
            title: this.$t('notifications.save.title'),
            text: this.$t('notifications.save.text')
          })

          this.clearInputs()

          this.$store.dispatch('popup/toggle', { page: this.page, popup: this.name })
        })
      }
    },
    clearInputs() {
      this.arrestation.offences.splice(0, this.arrestation.offences.length)
      this.addOffence()

      this.arrestation = Object.assign({}, this.initialArrestation)
      this.$refs.civilianSearchInput.clearInput()
    },
    openExistingArrestation() {
      this.clearInputs()

      if (!this.arrestationId) {
        return
      }

      this.$store.dispatch('police/fetchArrestation', this.arrestationId).then(arrestation => {
        this.arrestation.date = this.moment(arrestation.arrestationDate).format('YYYY-MM-DD')
        this.arrestation.time = this.moment(arrestation.arrestationDate).format('HH:mm')
        this.arrestation.location = arrestation.location
        this.defendantId = arrestation.defendant.id

        // Safe guard, make sure this.defendantId was loaded
        setTimeout(() => {
          this.$refs.civilianSearchInput.forceRefresh()
        }, 100)

        this.arrestation.offences = arrestation.offences.map(offence => {
          return {
            description: offence.description,
            act: offence.act,
            charge: {
              status: offence.chargeStatus,
              type: offence.chargeType,
              time: offence.chargeTime,
              timeFrame: offence.chargeTimeFrame,
              isOpen: false
            }
          }
        })
      })
    }
  },
  computed: {
    canSubmit() {
      return (
        this.arrestation.date &&
        this.arrestation.time &&
        this.arrestation.location &&
        this.arrestation.defendant &&
        this.arrestation.defendant.id &&
        this.arrestation.offences.length > 0 &&
        this.arrestation.offences.filter(
          offence =>
            offence.description &&
            offence.act &&
            (offence.charge.time || offence.charge.time === 0) &&
            offence.charge.time >= 0
        ).length > 0
      )
    }
  },
  watch: {
    arrestationId: {
      handler(val, oldVal) {
        this.openExistingArrestation()
      }
    },
    defendant: {
      handler(val, oldVal) {
        this.defendantId = val
      }
    }
  },
  mounted() {
    if (!this.issuer) {
      throw new Error('You must specify an issuer (ID) as a prop for the arrestation popup')
    }

    if (!this.action) {
      throw new Error('You must specify an action (create, view or edit) as a prop for the arrestation popup')
    }

    if (this.defendant) {
      this.defendantId = this.defendant
    }

    if (this.action !== 'view') {
      this.timeUpdateTimeoutFn = setTimeout(() => {
        this.currentDateTime = this.moment()
        this.timeUpdateIntervalFn = setInterval(() => {
          this.currentDateTime = this.moment()
        }, 1000 * 60)
      }, 60000 - (new Date().getTime() % 60000))
    }

    this.initialArrestation = Object.assign({}, this.arrestation)
  },
  beforeDestroy() {
    if (this.timeUpdateTimeoutFn) {
      clearTimeout(this.timeUpdateTimeoutFn)
    }
    if (this.timeUpdateIntervalFn) {
      clearInterval(this.timeUpdateIntervalFn)
    }
  }
}
</script>
