export default {
  methods: {
    unloadHandler: function(event) {
      if (
        this.$route.name === 'police' &&
        this.$store.state.selection.character.officer &&
        this.$store.state.selection.unit
      ) {
        this.$store.dispatch('police/setOfficerAsLeaving', {
          officerId: this.$store.state.selection.character.officer.id,
          unitId: this.$store.state.selection.unit.id
        })
      }

      /* NOTE: DISABLED BECAUSE OF ADSENCE
        // if (process.env.NODE_ENV !== 'development') {
        // Cancel the event as stated by the standard.
        event.preventDefault()
        // Chrome requires returnValue to be set.
        event.returnValue = ''
        // }
      */
    }
  },
  created() {
    window.addEventListener('beforeunload', this.unloadHandler)
  },
  beforeDestroy() {
    window.removeEventListener('beforeunload', this.unloadHandler)
  },
  beforeRouteLeave(to, from, next) {
    if (to.query.skipLeaveConfirmation) {
      next()
    } else {
      this.$swal({
        title: this.$t('unload-helper_mixin.dialog.title'),
        text: this.$t('unload-helper_mixin.dialog.text'),
        type: 'warning',
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: this.$t('unload-helper_mixin.dialog.confirm')
      }).then(result => {
        if (result.value) {
          next()
          this.$store.dispatch('police/updatePanicSoundIntervalFn', null)
          this.$store.dispatch('broadcastMessage/updateRepeatIntervalFn', null)
        } else {
          next(false)
        }
      })
    }
  }
}
