<template>
  <Popup
    :title="title"
    :page="page"
    :name="name"
    :parent="parent"
    :width="width"
    :height="height"
    @close="$emit('close')"
    class="violation default"
  >
    <div class="_content col-pc-10 col-lgmobile-10 col-margin-auto">
      <div class="citation">
        <div class="main-form">
          <div class="frame">
            <div class="row archived-warning" v-if="action === 'view'" style="margin-bottom: 15px;">
              <div class="banner orange error">
                <p class="uk-text-center">{{ $t('violation_component.read-only') }}</p>
              </div>
            </div>
            <div class="row">
              <div class="form">
                <div class="tr">
                  <div class="td date col-pc-6 col-lgmobile-6">
                    <p>
                      {{ $t('violation_component.date') }}
                      <a
                        v-if="action !== 'view' && moment(currentDateTime).format('YYYY-MM-DD') !== citation.date"
                        @click="updateDate"
                        >{{ $t('violation_component.now') }}</a
                      >
                    </p>
                    <VueDateTimePicker
                      id="citation-date"
                      :onlyDate="true"
                      inputClasses="no-border"
                      format="YYYY-MM-DD"
                      formatted="YYYY-MM-DD"
                      v-model="$v.citation.date.$model"
                      :disabled="action === 'view'"
                    ></VueDateTimePicker>
                  </div>
                  <div class="td time col-pc-6 col-lgmobile-6">
                    <p>
                      {{ $t('violation_component.time') }}
                      <a
                        v-if="action !== 'view' && moment(currentDateTime).format('HH:mm') !== citation.time"
                        @click="updateTime"
                        >{{ $t('violation_component.now') }}</a
                      >
                    </p>
                    <VueDateTimePicker
                      id="arrestation-time"
                      :onlyTime="true"
                      inputClasses="no-border"
                      :format="moment.locale() === 'en' ? 'h:mm A' : 'HH:mm'"
                      formatted="LT"
                      outputFormat="HH:mm:ss"
                      v-model="$v.citation.time.$model"
                      :disabled="action === 'view'"
                    ></VueDateTimePicker>
                  </div>
                </div>
                <div class="tr">
                  <div class="td type col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.type') }}</p>
                    <vSelect
                      :clearable="false"
                      :disabled="action === 'view'"
                      v-model="citation.type"
                      :options="[
                        { label: $t('violation_component.traffic-citation'), value: 'TRAFFIC_CITATION' },
                        { label: $t('violation_component.other-citation'), value: 'OTHER_CITATION' }
                      ]"
                      :reduce="type => type.value"
                      class="custom-select blank font-weight-medium"
                    ></vSelect>
                  </div>
                  <div class="td col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.location') }}</p>
                    <CustomInput
                      class="col-pc-12 primary custom-input uppercase"
                      inputClasses="col-pc-12 blank"
                      :readonly="action === 'view'"
                      type="text"
                      :placeholder="$t('violation_component.location_placeholder')"
                      v-model="$v.citation.location.$model"
                      :model="$v.citation.location"
                    ></CustomInput>
                  </div>
                </div>
                <div class="tr">
                  <div class="td full-name col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.violator-full-name') }}</p>
                    <CivilianSearchInput
                      class="searchbar custom-input primary col-pc-12 col-lgmobile-12"
                      :id="defendantId"
                      :readonly="disableCivilianSearch || action === 'view'"
                      @selectedCivilian="selectCivilian($event)"
                      ref="civilianSearchInput"
                    />
                  </div>
                  <div class="td gender col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.violator-gender') }}</p>
                    <CustomInput
                      class="col-all-12 custom-input primary"
                      inputClasses="col-all-12 blank"
                      type="text"
                      :value="citation.defendant.gender"
                      readonly="true"
                    ></CustomInput>
                  </div>
                </div>
                <!-- <div class="tr">
                  <div class="td street-number col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.violator-address') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12"
                        :class="{ readonly_empty: citation.violator.streetNumber === '' }"
                        type="text"
                        v-model="citation.violator.streetNumber"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="td city col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.violator-city') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12"
                        :class="{ readonly_empty: citation.violator.city === '' }"
                        type="text"
                        v-model="citation.violator.city"
                        readonly
                      />
                    </div>
                  </div>
                </div>-->
                <!-- <div class="tr">
                  <div class="td state col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.violator-state') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12"
                        :class="{ readonly_empty: citation.violator.state === '' }"
                        type="text"
                        v-model="citation.violator.state"
                        readonly
                      />
                    </div>
                  </div>
                  <div class="td postal-code col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.violator-postal-code') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12"
                        :class="{ readonly_empty: citation.violator.postalCode === '' }"
                        type="text"
                        v-model="citation.violator.postalCode"
                        readonly
                      />
                    </div>
                  </div>
                </div>-->
              </div>
              <div class="form offences">
                <h3>{{ $t('violation_component.offences') }}</h3>
                <div class="tr offence-titles">
                  <div class="th col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.description') }}</p>
                  </div>
                  <div class="th col-pc-3 col-lgmobile-3">
                    <p>{{ $t('violation_component.act') }}</p>
                  </div>
                  <div class="th col-pc-3 col-lgmobile-3" v-if="action !== 'view'">
                    <p>
                      {{ $t('violation_component.fine') }}
                      <i
                        class="fas fa-question-circle tooltip"
                        :uk-tooltip="'title:' + $t('violation_component.fine_tooltip') + ';' + 'pos: top'"
                      ></i>
                    </p>
                  </div>
                  <div class="th col-pc-3 col-lgmobile-3" v-if="action !== 'view'">
                    <p>{{ $t('actions.delete') }}</p>
                  </div>
                  <div class="th col-pc-6 col-lgmobile-6" v-if="action === 'view'">
                    <p>{{ $t('violation_component.fine') }}</p>
                  </div>
                </div>
                <div class="tr offence" v-for="(offence, index) in citation.offences" :key="'offence-' + index">
                  <div class="td description col-pc-6 col-lgmobile-6">
                    <div class="custom-input">
                      <input
                        class="col-all-12 blank"
                        :readonly="action === 'view'"
                        type="text"
                        :placeholder="$t('violation_component.description_placeholder')"
                        v-model="offence.description"
                      />
                    </div>
                  </div>
                  <div class="td act col-pc-3 col-lgmobile-3">
                    <div class="custom-input">
                      <input
                        class="col-all-12 blank"
                        :readonly="action === 'view'"
                        type="text"
                        :placeholder="'(' + $t('violation_component.act_placeholder') + ')'"
                        v-model="offence.act"
                      />
                    </div>
                  </div>
                  <div class="td fine col-pc-3 col-lgmobile-3" v-if="action !== 'view'">
                    <div class="custom-input primary line">
                      <div class="input-icon">
                        <i class="fas fa-dollar-sign"></i>
                        <input
                          class="col-all-12 blank"
                          v-model="offence.fine"
                          min="0"
                          step="0.01"
                          oninput="validity.valid || (value = '');"
                          type="number"
                          :placeholder="$t('violation_component.fine_placeholder')"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="td fine col-pc-3 col-lgmobile-3" v-if="action !== 'view'">
                    <button class="primary" :disabled="action === 'view'" @click="deleteOffence(index)">
                      {{ $t('actions.delete') }}
                    </button>
                  </div>
                  <div class="td fine col-pc-6 col-lgmobile-6" v-if="action === 'view'">
                    <div v-if="offence.fine">
                      <span v-if="$route.params.lang === 'en'" class="currency-before">$</span>
                      {{ offence.fine }}
                      <span v-if="$route.params.lang === 'fr'" class="currency-after">$</span>
                    </div>
                    <div v-if="!offence.fine">{{ $t('violation_component.warning') }}</div>
                  </div>
                </div>
              </div>
              <div style="margin-top: 10px;" v-if="action !== 'view'">
                <a @click="addOffence">{{ $t('violation_component.add-offences') }}</a>
              </div>
              <div class="form vehicle" v-show="citation.type === 'TRAFFIC_CITATION'">
                <h3>{{ $t('violation_component.vehicle') }}</h3>
                <div class="tr">
                  <div class="td plate col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.plate') }}</p>
                    <VehicleSearchInput
                      :id="vehicleId"
                      :readonly="action === 'view'"
                      @selectedVehicle="selectVehicle($event)"
                      ref="vehicleSearchInput"
                      class="col-pc-12"
                    />
                  </div>
                  <div class="td col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.model') }}</p>
                    <div class="custom-input">
                      <input class="col-all-12 blank" type="text" v-model="citation.vehicle.model" readonly />
                    </div>
                  </div>
                </div>
                <div class="tr">
                  <div class="td col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.color') }}</p>
                    <div class="custom-input">
                      <input class="col-all-12 blank" type="text" :value="citation.vehicle.color" readonly />
                    </div>
                  </div>
                  <div class="td col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.wanted') }}</p>
                    <div class="custom-input">
                      <input
                        class="col-all-12 blank"
                        type="text"
                        :placeholder="$t('violation_component.coming-soon')"
                        readonly
                      />
                    </div>
                  </div>
                </div>
                <div class="tr">
                  <div class="td col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.owner-first-name') }}</p>
                    <div class="custom-input">
                      <input class="col-all-12 blank" type="text" v-model="citation.vehicle.owner.firstName" readonly />
                    </div>
                  </div>
                  <div class="td col-pc-6 col-lgmobile-6">
                    <p>{{ $t('violation_component.owner-last-name') }}</p>
                    <div class="custom-input">
                      <input class="col-all-12 blank" type="text" v-model="citation.vehicle.owner.lastName" readonly />
                    </div>
                  </div>
                </div>
              </div>
              <div class="button" v-if="action !== 'view'">
                <button class="primary col-pc-12 col-lgmobile-12" :disabled="!canSubmit" @click="commitCitation">
                  {{ $t('actions.submit') }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Popup>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>
<style lang="less" scoped src="@/assets/less/components/reports.less"></style>

<script>
import Popup from '@/components/Popup'
import CivilianSearchInput from '@/components/CivilianSearchInput'
import VehicleSearchInput from '@/components/VehicleSearchInput'
import vSelect from 'vue-select'

export default {
  components: {
    Popup,
    CivilianSearchInput,
    VehicleSearchInput,
    vSelect
  },
  data() {
    return {
      citation: {
        date: this.moment().format('YYYY-MM-DD'),
        time: this.moment().format('HH:mm'),
        type: 'TRAFFIC_CITATION',
        location: '',
        defendant: {
          gender: ''
        },
        vehicle: {
          plate: '',
          model: '',
          color: '',
          owner: {
            firstName: '',
            lastName: ''
          }
        },
        offences: [
          {
            description: '',
            act: '',
            fine: ''
          }
        ]
      },
      initialCitation: null,
      defendantId: null,
      vehicleId: null,
      timeUpdateIntervalFn: null,
      timeUpdateTimeoutFn: null,
      currentDateTime: this.moment()
    }
  },
  validations: {
    citation: {
      date: {},
      time: {},
      location: {}
    }
  },
  props: {
    title: String,
    page: String,
    name: String,
    width: Number,
    height: Number,
    parent: Boolean,
    issuer: String,
    action: String,
    defendant: String,
    disableCivilianSearch: {
      type: Boolean,
      default: false
    },
    citationId: String
  },
  methods: {
    updateDate() {
      this.citation.date = this.moment().format('YYYY-MM-DD')
    },
    updateTime() {
      this.citation.time = this.moment().format('HH:mm')
    },
    openPopup: function() {
      if (this.active === true) {
        this.$modal.show('violation')
      }
    },
    addOffence() {
      this.citation.offences.push({
        description: '',
        act: '',
        fine: ''
      })
    },
    deleteOffence(index) {
      this.citation.offences.splice(index, 1)

      if (this.citation.offences.length === 0) {
        this.addOffence()
      }
    },
    selectCivilian(civilian) {
      this.citation.defendant = civilian
    },
    selectVehicle(vehicle) {
      this.citation.vehicle = vehicle
    },
    commitCitation() {
      if (this.action === 'create') {
        if (!this.canSubmit) {
          return
        }

        let vehicle = null
        if (this.citation.type === 'TRAFFIC_CITATION') {
          vehicle = { connect: { id: this.citation.vehicle.id } }
        }

        const civilian = {
          civilianId: this.citation.defendant.id,
          citations: {
            create: {
              violationDate: new Date(this.citation.date + ' ' + this.citation.time),
              location: this.citation.location,
              issuer: { connect: { id: this.issuer } },
              offences: {
                create: this.citation.offences
                  .filter(offence => offence.description && offence.act)
                  .map(offence => {
                    return {
                      description: offence.description,
                      act: offence.act,
                      fine: offence.fine ? parseFloat(offence.fine) : null
                    }
                  })
              },
              vehicle
            }
          }
        }

        this.$store
          .dispatch('civilian/updateCivilian', civilian)
          .then(response => {
            this.$notify({
              type: 'success',
              title: this.$t('notifications.save.title'),
              text: this.$t('notifications.save.text')
            })

            this.clearInputs()

            this.$store.dispatch('popup/toggle', { page: this.page, popup: this.name })
          })
          .catch(error => {
            this.$store.dispatch('errorManager/showError', { error: error })
          })
      }
    },
    clearInputs() {
      this.citation.offences.splice(0, this.citation.offences.length)
      this.addOffence()

      this.citation = Object.assign({}, this.initialCitation)
      this.$refs.civilianSearchInput.clearInput()

      if (this.$refs.vehicleSearchInput) {
        this.$refs.vehicleSearchInput.clearInput()
      }
    },
    openExistingCitation() {
      this.clearInputs()

      if (!this.citationId) {
        return
      }

      this.$store.dispatch('police/fetchCitation', this.citationId).then(citation => {
        this.citation.date = this.moment(citation.violationDate).format('YYYY-MM-DD')
        this.citation.time = this.moment(citation.violationDate).format('HH:mm')
        this.citation.location = citation.location
        this.defendantId = citation.defendant.id

        // Safe guard, make sure this.defendantId was loaded
        setTimeout(() => {
          this.$refs.civilianSearchInput.forceRefresh()
        }, 100)

        if (citation.vehicle) {
          this.citation.type = 'TRAFFIC_CITATION'
          this.vehicleId = citation.vehicle.id
          setTimeout(() => {
            this.$refs.vehicleSearchInput.forceRefresh()
          }, 100)
        } else {
          this.citation.type = 'OTHER_CITATION'
        }

        this.citation.offences = citation.offences.map(offence => {
          return {
            description: offence.description,
            act: offence.act,
            fine: offence.fine
          }
        })
      })
    }
  },
  computed: {
    canSubmit() {
      return (
        this.citation.date &&
        this.citation.time &&
        this.citation.location &&
        this.citation.defendant &&
        this.citation.defendant.id &&
        this.citation.offences.length > 0 &&
        this.citation.offences.filter(offence => offence.description && offence.act).length > 0 &&
        (this.citation.type === 'TRAFFIC_CITATION' ? this.citation.vehicle && this.citation.vehicle.id : true)
      )
    }
  },
  watch: {
    citationId: {
      handler(val, oldVal) {
        this.openExistingCitation()
      }
    },
    defendant: {
      handler(val, oldVal) {
        this.defendantId = val
      }
    }
  },
  mounted() {
    if (!this.issuer) {
      throw new Error('You must specify an issuer (ID) as a prop for the arrestation popup')
    }

    if (!this.action) {
      throw new Error('You must specify an action (create or edit) as a prop for the arrestation popup')
    }

    if (this.defendant) {
      this.defendantId = this.defendant
    }

    if (this.action !== 'view') {
      this.timeUpdateTimeoutFn = setTimeout(() => {
        this.currentDateTime = this.moment()
        this.timeUpdateIntervalFn = setInterval(() => {
          this.currentDateTime = this.moment()
        }, 1000 * 60)
      }, 60000 - (new Date().getTime() % 60000))
    }

    this.initialCitation = Object.assign({}, this.citation)
  }
}
</script>
