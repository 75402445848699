<template>
  <div
    class="search col-pc-10 col-lgmobile-10 col-margin-auto"
    v-click-outside="
      () => {
        showResults = false
      }
    "
  >
    <div class="searchbar">
      <div class="input-icon secondary col-pc-12 col-lgmobile-12">
        <i class="fas fa-search"></i>
        <input
          class="box col-pc-12 col-lgmobile-12"
          type="text"
          :placeholder="$t('search-vehicle_component.search-a-vehicle')"
          :readonly="readonly"
          @input="debounceSearch"
          @focus="searchQuery !== '' && !readonly ? (showResults = true) : (showResults = false)"
          v-model="searchQuery"
        />
        <i style="margin: auto;" class="fas fa-circle-notch fa-spin" v-show="isLoading"></i>
      </div>
    </div>
    <div class="searchbar-results col-pc-12 col-lgmobile-12" v-if="showResults">
      <ul>
        <li v-show="searchResults.length === 0">{{ $t('search-vehicle_component.no-result') }}</li>
        <li
          v-for="searchResult in searchResults"
          :key="searchResult.item.id"
          @click="selectVehicle(searchResult.item.id)"
        >
          {{ searchResult.item.plate }}
        </li>
      </ul>
    </div>
  </div>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import { debounce } from 'lodash'
import ClickOutside from 'vue-click-outside'

export default {
  name: 'VehicleSearchInput',
  data() {
    return {
      isLoading: false,
      searchQuery: '',
      searchResults: [],
      showResults: false,
      vehicle: null
    }
  },
  props: {
    id: String,
    readonly: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    selectVehicle: function(vehicleId) {
      this.showResults = false

      this.$store
        .dispatch('civilian/fetchVehicleForSearch', vehicleId)
        .then(response => {
          this.vehicle = response
          this.searchQuery = this.vehicle.plate

          this.$emit('selectedVehicle', response)
        })
        .catch(error => {
          this.$store.dispatch('errorManager/showError', { error: error })
        })
    },
    debounceSearch: debounce(function(showResults = true) {
      this.isLoading = true
      this.$store.dispatch('civilian/searchVehicles', this.searchQuery).then(response => {
        this.isLoading = false

        this.searchResults = response
        this.showResults = showResults
      })
    }, 200),
    clearInput() {
      // Should be called by a parent component
      this.searchQuery = ''
    },
    forceRefresh() {
      // Should be called by a parent component
      if (this.id) {
        this.selectVehicle(this.id)
        this.debounceSearch(false)
      }
    }
  },
  mounted() {
    if (this.id) {
      this.selectVehicle(this.id)
      this.debounceSearch(false)
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
