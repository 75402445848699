import { mapGetters } from 'vuex'

export default {
  apollo: {
    communityPermissions: {
      query: require('@/graphql/user/fetchCommunityPermissions.gql'),
      variables() {
        return {
          communityMemberId: this.$store.state.selection.community ? this.$store.state.selection.community.id : ''
        }
      },
      update: data => data.communityMember,
      subscribeToMore: {
        document: require('@/graphql/user/subscribeToCommunityPermissions.gql'),
        variables() {
          return {
            communityMemberId: this.$store.state.selection.community ? this.$store.state.selection.community.id : ''
          }
        },
        updateQuery(previousResult, { subscriptionData }) {
          const member = subscriptionData.data.communityMember

          // FIXME: For some reason, the where in the subscription doesn't seem to work
          if (
            this.$store.state.selection.community &&
            member.previousValues.id !== this.$store.state.selection.community.id
          ) {
            return
          }

          // FIXME: previousResult is always the same as current (member.node)
          // console.log(previousResult.communityMember.permissionsValue, member.node.permissionsValue)

          // if (previousResult.communityMember.permissionsValue === member.node.permissionsValue) return

          // TODO: Put this in a global function, it is also used in the community selection
          this.$store.dispatch('selection/fetchCommunities').then(response => {
            if (this.$store.state.selection.community) {
              response.forEach(community => {
                if (community.id === this.$store.state.selection.community.id) {
                  if (
                    !this.checkCommunityStatus(community.community.paymentData.status) ||
                    (!this.checkPermissions()(community.permissionsValue, 'accepted') &&
                      !this.checkPermissions()(community.permissionsValue, 'owner'))
                  ) {
                    this.$store.commit('selection/community', null)
                  } else {
                    this.$store.commit('selection/community', community)
                  }
                }
              })
            }
          })
          this.$store.dispatch('auth/refreshToken').then(() => {
            if (member.mutation === 'DELETED') {
              this.$notify({
                type: 'warn',
                title: this.$t('notifications.removed-from-community.title'),
                text: this.$t('notifications.removed-from-community.text')
              })
              this.$store.commit('selection/community', null)

              this.$router.push({
                name: 'communities',
                query: { skipLeaveConfirmation: true }
              })
            } else {
              this.verifyRoute(member.node.permissionsValue, 'dispatch', 'dispatch', 'roles')
              this.verifyRoute(member.node.permissionsValue, 'police', 'police', 'roles')
              this.verifyRoute(member.node.permissionsValue, 'civilian', 'civilian', 'roles')
              this.verifyRoute(member.node.permissionsValue, 'admin', 'admin', 'roles')
            }
          })
        }
      }
    }
  },
  methods: {
    ...mapGetters('auth', ['checkCurrentPermissions', 'checkPermissions']),
    verifyRoute(currentPermissions, permissionToCheck, routeToCheck, routeToRedirect) {
      if (
        this.$route.name &&
        this.$route.name.includes(routeToCheck) &&
        this.checkCurrentPermissions()(permissionToCheck) &&
        !this.checkPermissions()(currentPermissions, permissionToCheck)
      ) {
        this.$notify({
          type: 'warn',
          title: this.$t('notifications.permission-removed.title'),
          text: this.$t('notifications.permission-removed.text', { permission: permissionToCheck })
        })

        if (this.$route.name.includes('civilian')) {
          this.$router.push({ hash: '#edit' })
        } else {
          this.$router.push({
            name: routeToRedirect,
            query: { skipLeaveConfirmation: true }
          })
        }
      }
    },
    verifyCurrentRoute(permissionToCheck, routeToCheck, routeToRedirect) {
      if (
        this.$route.name &&
        this.$route.name.includes(routeToCheck) &&
        !this.checkCurrentPermissions()(permissionToCheck) &&
        !this.checkCurrentPermissions()('owner')
      ) {
        this.$notify({
          type: 'warn',
          title: this.$t('notifications.no-access.title'),
          text: this.$t('notifications.no-access.text', { permission: permissionToCheck })
        })

        this.$router.push({
          name: routeToRedirect,
          query: { skipLeaveConfirmation: true }
        })
      }
    },
    checkCommunityStatus(status) {
      const accessibleStatus = ['INCOMPLETE', 'TRIALING', 'ACTIVE', 'PAST_DUE']

      return accessibleStatus.includes(status)
    }
  },
  mounted() {
    this.verifyCurrentRoute('dispatch', 'dispatch', 'roles')
    this.verifyCurrentRoute('police', 'police', 'roles')
    this.verifyCurrentRoute('admin', 'admin', 'roles')
  }
}
