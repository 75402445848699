<template>
  <VueDraggableResizable
    v-if="boostrapped"
    :parent="parent"
    :active="popupData()(page, name).active"
    :x="popupData()(page, name).x"
    :y="popupData()(page, name).y"
    :z="popupData()(page, name).z"
    :w="width"
    :h="height"
    :drag-handle="'.drag-handle'"
    :draggable="draggable"
    :resizable="resizable"
    :class="{ active: popupData()(page, name).active, popupClass }"
    @dragstop="onDragStop"
    @dragging="
      () => {
        popupData()(page, name).z === 100 ? $store.dispatch('popup/focus', { page: page, popup: name, z: 160 }) : ''
      }
    "
    class="popup"
  >
    <div
      class="popup-container"
      @click="
        popupData()(page, name).z === 100 ? $store.dispatch('popup/focus', { page: page, popup: name, z: 150 }) : ''
      "
      v-click-outside="
        () => {
          popupData()(page, name).z > 100 ? $store.dispatch('popup/blur', { page: page, popup: name }) : ''
        }
      "
    >
      <div class="popup-header drag-handle noselect" :class="{ active: draggable }">
        <div class="title">{{ title }}</div>
        <div class="buttons">
          <div class="close">
            <button @click="close">
              <i class="fas fa-times"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="content">
        <slot></slot>
      </div>
    </div>
  </VueDraggableResizable>
</template>
<style lang="less" scoped src="@/assets/less/components/popup-styles.less"></style>

<script>
import VueDraggableResizable from '@/components/VueDraggableResizable'
import 'vue-draggable-resizable/dist/VueDraggableResizable.css'
import ClickOutside from 'vue-click-outside'
import { mapGetters } from 'vuex'

export default {
  components: {
    VueDraggableResizable
  },
  data() {
    return {
      boostrapped: false
    }
  },
  props: {
    page: {
      type: String,
      required: true,
      validator: function(value) {
        // The value must match one of these strings
        return ['police', 'dispatch', 'civilian'].indexOf(value) !== -1
      }
    },
    name: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    resizable: {
      type: Boolean,
      default: false
    },
    draggable: {
      type: Boolean,
      default: true
    },
    parent: {
      type: Boolean,
      default: true
    },
    popupClass: String
  },
  methods: {
    ...mapGetters('popup', ['popupData']),
    close: function() {
      this.$emit('close')
      this.$store.dispatch('popup/toggle', { page: this.page, popup: this.name })
    },
    onDragStop: function(x, y) {
      this.$store.dispatch('popup/savePosition', { page: this.page, popup: this.name, x, y })
    }
  },
  mounted() {
    if (this.page && this.name) {
      if (!this.popupData()(this.page, this.name)) {
        this.$store.dispatch('popup/bootstrap', { page: this.page, popup: this.name }).then(() => {
          this.boostrapped = true
        })
      } else {
        this.boostrapped = true
      }
    }
  },
  directives: {
    ClickOutside
  }
}
</script>
