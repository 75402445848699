var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.boostrapped)?_c('VueDraggableResizable',{staticClass:"popup",class:{ active: _vm.popupData()(_vm.page, _vm.name).active, popupClass: _vm.popupClass },attrs:{"parent":_vm.parent,"active":_vm.popupData()(_vm.page, _vm.name).active,"x":_vm.popupData()(_vm.page, _vm.name).x,"y":_vm.popupData()(_vm.page, _vm.name).y,"z":_vm.popupData()(_vm.page, _vm.name).z,"w":_vm.width,"h":_vm.height,"drag-handle":'.drag-handle',"draggable":_vm.draggable,"resizable":_vm.resizable},on:{"dragstop":_vm.onDragStop,"dragging":() => {
      _vm.popupData()(_vm.page, _vm.name).z === 100 ? _vm.$store.dispatch('popup/focus', { page: _vm.page, popup: _vm.name, z: 160 }) : ''
    }}},[_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(
      () => {
        _vm.popupData()(_vm.page, _vm.name).z > 100 ? _vm.$store.dispatch('popup/blur', { page: _vm.page, popup: _vm.name }) : ''
      }
    ),expression:"\n      () => {\n        popupData()(page, name).z > 100 ? $store.dispatch('popup/blur', { page: page, popup: name }) : ''\n      }\n    "}],staticClass:"popup-container",on:{"click":function($event){_vm.popupData()(_vm.page, _vm.name).z === 100 ? _vm.$store.dispatch('popup/focus', { page: _vm.page, popup: _vm.name, z: 150 }) : ''}}},[_c('div',{staticClass:"popup-header drag-handle noselect",class:{ active: _vm.draggable }},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.title))]),_c('div',{staticClass:"buttons"},[_c('div',{staticClass:"close"},[_c('button',{on:{"click":_vm.close}},[_c('i',{staticClass:"fas fa-times"})])])])]),_c('div',{staticClass:"content"},[_vm._t("default")],2)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }